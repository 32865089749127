// JoinAnchors.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const JoinAnchors = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
    const [wantsToReceiveInformationAnchors, setWantsToReceiveInformationAnchors] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); // Estat per controlar si s'ha enviat el formulari

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!acceptPrivacyPolicy || !wantsToReceiveInformationAnchors) {
            alert('You must accept the Privacy Policy and agree to receive information.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:5001/api'}/anchors-candidates`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, acceptPrivacyPolicy, wantsToReceiveInformationAnchors }),
            });

            if (response.ok) {
                setFormSubmitted(true); // Actualitza l'estat per ocultar tot el contingut previ
            } else {
                const data = await response.json();
                alert(data.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <div>
            {formSubmitted ? ( // Mostra només el missatge final si s'ha enviat el formulari
                <h3>
                    {t('Thank you for supporting voice research!')}
                </h3>
            ) : ( // Mostra tot el contingut previ i el formulari si no s'ha enviat
                <>
                    <h1>{t('contribute_to_future_perceptual_anchors')}</h1>
                    <p className="centered-text"><strong>Thank you for your interest in contributing to our research!</strong></p>
                    <p className="centered-text">Our experiment will be available soon, as we are currently processing the sample collections to ensure the highest quality for this study.</p>
                    <p className="centered-text">
                        If you would like to be notified when the experiment is ready, please leave your email address below.
                        We will send you an invitation to participate as soon as everything is set up.
                    </p>

                    <form onSubmit={handleSubmit}>
                        <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
                            {t('Email')}:
                        </label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="input-field"
                        />

                        <label className="form-label">
                            <input
                                type="checkbox"
                                checked={wantsToReceiveInformationAnchors}
                                onChange={(e) => setWantsToReceiveInformationAnchors(e.target.checked)}
                                required
                            />
                            I want to receive information when the experiment is ready.
                        </label>

                        <p className="data-protection-info" style={{ fontSize: '0.8em', marginTop: '10px' }}>
                            <strong>DATA PROTECTION INFORMATION</strong><br />
                            Controller: Neus Calaf Gozalo - info@all-voiced.com. Purposes: To notify you when the experiment is ready. Legal Basis: Consent of the data subject by filling out the form. Disclosures: No disclosures are expected. Rights: You can revoke your consent at any time, as well as exercise your rights of access, rectification, deletion, objection, restriction, and data portability by contacting the data controller. In case of disputes, you can file a complaint with the Spanish Data Protection Agency. Additional Information: You can find more information at the Legal Notice link.
                        </p>
                        <label className="form-label">
                            <input
                                type="checkbox"
                                checked={acceptPrivacyPolicy}
                                onChange={(e) => setAcceptPrivacyPolicy(e.target.checked)}
                                required
                            />
                            I have read and accept the <a href="/privacy-policy">Privacy Policy</a>.
                        </label>

                        <button type="submit" className="button-center">
                            {t('Submit')}
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default JoinAnchors;