// AppRoutes.jsx

// Utilities
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RolesProvider } from './auth-and-backend/auth/rolesContext'; // Assegura't d'importar-ho
import ProtectedRoute from './auth-and-backend/auth/ProtectedRoute';
import ScrollToTop from './ScrollToTop';
import ConfirmationPage from './auth-and-backend/auth/ConfirmationPage';
import EmailVerification from './auth-and-backend/auth/EmailVerification';
import PasswordResetComponent from './auth-and-backend/auth/PasswordResetComponent';
import LoginComponent from './auth-and-backend/auth/LoginComponent';
import UserProfile from './auth-and-backend/auth/UserProfile';

// Open Views
import Header from './views/app-views/Header';
import Footer from './views/app-views/Footer';
import Landing from './views/open-access/Landing';
import ResourcesForTraining from './views/open-access/ResourcesForTraining';
import ResourcesForTeaching from './views/open-access/ResourcesForTeaching';
import ResourcesForResearch from './views/open-access/ResourcesForResearch';
import AllTheResources from './views/open-access/AllTheResources';
import HowToCite from './views/open-access/HowToCite';
import LegalNotice from './views/open-access/LegalNotice';
import PrivacyPolicy from './views/open-access/PrivacyPolicy';
import ResearchAtAllVoiced from './views/open-access/ResearchAtAllVoiced';
import AboutUs from './views/open-access/AboutUs';
import ASHAConventionAnchors from './experiments/anchors/ASHAConventionAnchors';
import JoinAnchors from './experiments/anchors/JoinAnchors';
import GlobalDatabaseProjectInformationParticipants from './views/open-access/GlobalDatabaseProjectInformationParticipants';
import GlobalDatabaseProjectInformedConsent from './views/open-access/GlobalDatabaseProjectInformedConsent';

// Open resources
import CAPEVClassroom from './cape-v/CAPEVClassroom';
import CAPEVScholar from './cape-v/CAPEVScholar';
import CAPEVCustomLab from './cape-v/CAPEVCustomLab';
import CAPEVClinic from './cape-v/CAPEVClinic';
import CAPEVWorkshop from './cape-v/CAPEVWorkshop';
import CAPEVWorkshopStudent from './cape-v/cape-v-workshop/cape-v-workshop-students-files/CAPEVWorkshopStudent'; 
import CAPEVWorkshopInstructor from './cape-v/cape-v-workshop/cape-v-workshop-instructor-files/CAPEVWorkshopInstructor'; 
import RBSTraining from './training/RBSTraining/RBSTraining';
import OSTraining from './training/OSTraining/OSTraining';
import OSClassroom from './training/OSTraining/OSClassroom';
import GuessTheDiagnosis from './training/diagnosis/GuessTheDiagnosis';

// Protected views
import AdminDashboard from './views/authenticated-routes/AdminDashboard';

// Protected resources
import EARV1 from './evaluation/ear-v1/EARV';
import EARV from './evaluation/ear-v/EARV';
import CAPEVTraining from './cape-v/CAPEVTraining';
import CapeVEntrenament from './cape-v/CapeVEntrenament';
import CapeVEntrenamiento from './cape-v/CapeVEntrenamiento';
import Anchors from './experiments/anchors/Anchors';
import VASexperiment from './experiments/vas-experiment/VASexperiment';
import RandomVASexperiment from './experiments/random-vas-experiment/RandomVASexperiment';
import FreeSortingTask from './experiments/free-sorting-task/FreeSortingTask';
import CollectionsFreeSortingTask from './experiments/collections-free-sorting-task/CollectionsFreeSortingTask';
import TwoAFCexperiment from './experiments/2AFC-experiment/TwoAFCexperiment';
import Training from './training/training-in-three-phases/Training';
import ConceptsFreeSortingTask from './concepts/free-sorting-task/ConceptsFreeSortingTask';

const AppRoutes = () => {
  return (
    <RolesProvider> {/* Ara el RolesProvider envolta tot */}
      <Header />
      <div className="flex-container">
        <div className="main-content">
          <div className="content-area">
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Landing />} />
                {/* Open views */}
                <Route path="/resources-for-autonomous-training" element={<ResourcesForTraining />} />
                <Route path="/resources-for-teaching-and-learning" element={<ResourcesForTeaching />} />
                <Route path="/resources-for-research" element={<ResourcesForResearch />} />
                <Route path="/all-the-resources" element={<AllTheResources />} />
                <Route path="/how-to-cite" element={<HowToCite />} />
                <Route path="/confirmation" element={<ConfirmationPage />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/password-reset/:token" element={<PasswordResetComponent />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/legal-notice" element={<LegalNotice />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/research-at-all-voiced" element={<ResearchAtAllVoiced />} />
                <Route path="/2024-asha-convention-anchors" element={<ASHAConventionAnchors />} />
                <Route path="/join-anchors" element={<JoinAnchors />} />
                <Route path="/global-database-project-information-participants" element={<GlobalDatabaseProjectInformationParticipants />} />
                <Route path="/global-database-project-informed-consent" element={<GlobalDatabaseProjectInformedConsent />} />


                {/* Open resources */}
                <Route path="/cape-v-training" element={<CAPEVTraining />} />
                <Route path="/cape-v-classroom" element={<CAPEVClassroom />} />
                <Route path="/cape-v-scholar" element={<CAPEVScholar />} />
                <Route path="/cape-v-custom-lab" element={<CAPEVCustomLab />} />
                <Route path="/cape-v-workshop" element={<CAPEVWorkshop />} />
                <Route path="/cape-v-workshop-student" element={<CAPEVWorkshopStudent />} />
                <Route path="/cape-v-workshop-instructor" element={<CAPEVWorkshopInstructor />} />
                <Route path="/rbs-training" element={<RBSTraining />} />
                <Route path="/os-training" element={<OSTraining />} />
                <Route path="/os-classroom" element={<OSClassroom />} />
                <Route path="/guess-the-diagnosis" element={<GuessTheDiagnosis />} />
              
                {/* Protected routes */}
                <Route path="/admin-dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />
                <Route path="/ear-v1" element={<ProtectedRoute allowedRoles={['admin', 'friend', 'student']}><EARV1 /></ProtectedRoute>} />
                <Route path="/ear-v" element={<ProtectedRoute allowedRoles={['admin']}><EARV /></ProtectedRoute>} />
                <Route path="/cape-v-entrenament" element={<ProtectedRoute allowedRoles={['admin', 'student', 'friend']}><CapeVEntrenament /></ProtectedRoute>} />
                <Route path="/cape-v-entrenamiento" element={<ProtectedRoute allowedRoles={['admin']}><CapeVEntrenamiento /></ProtectedRoute>} />
                <Route path="/cape-v-clinic" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVClinic /></ProtectedRoute>} />
                <Route path="/concepts" element={<ProtectedRoute allowedRoles={['admin', 'student']}><ConceptsFreeSortingTask /></ProtectedRoute>} />
                <Route path="/anchors" element={<ProtectedRoute allowedRoles={['admin', 'committee']}><Anchors /></ProtectedRoute>} />
                <Route path="/free-sorting-task" element={<ProtectedRoute allowedRoles={['admin']}><FreeSortingTask /></ProtectedRoute>} />
                <Route path="/collections-free-sorting-task" element={<ProtectedRoute allowedRoles={['admin', 'student']}><CollectionsFreeSortingTask /></ProtectedRoute>} />
                <Route path="/2-afc" element={<ProtectedRoute allowedRoles={['admin']}><TwoAFCexperiment /></ProtectedRoute>} />
                <Route path="/vas-experiment" element={<ProtectedRoute allowedRoles={['admin', 'collaborator']}><VASexperiment /></ProtectedRoute>} />
                <Route path="/random-vas-experiment" element={<ProtectedRoute allowedRoles={['admin']}><RandomVASexperiment /></ProtectedRoute>} />
                <Route path="/training-in-three-phases" element={<ProtectedRoute allowedRoles={['admin']}><Training /></ProtectedRoute>} />
              </Routes>
            </ScrollToTop>
          </div>
        </div>
        <Footer />
      </div>
    </RolesProvider>
  );
};

export default AppRoutes;