// TreeMenu.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TreeMenu = ({ onSelectionChange = () => {} }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [vasValues, setVasValues] = useState({}); // Per gestionar els valors VAS

  const toggleExpand = (key) => {
    setExpanded((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleSelect = (key, variable, evaluationType) => {
    if (!variable) return; // Ignora nodes sense una variable associada
    setSelectedItems((prev) => {
      const updated = prev[variable]
        ? { ...prev, [variable]: undefined } // Deselect
        : { ...prev, [variable]: { evaluationType } }; // Select amb tipus d'avaluació

      onSelectionChange(Object.keys(updated).filter((key) => updated[key])); // Inform parent
      return updated;
    });
  };

  const handleVasChange = (variable, value) => {
    setVasValues((prev) => ({ ...prev, [variable]: value }));
  };

  const treeData = [
    {
      label: 'pitch',
      variable: null,
      children: [
        {
          label: 'pitch_range',
          variable: null,
          children: [
            { label: 'adecuate_pitch', variable: 'adecuatePitch', evaluationType: 'checkbox' },
            { label: 'too_high', variable: 'tooHigh', evaluationType: 'VAS' },
            { label: 'too_low', variable: 'tooLow', evaluationType: 'VAS' },
          ],
        },
        {
          label: 'pitch_accuracy',
          variable: null,
          children: [
            { label: 'accurated_pitch', variable: 'accuratedPitch', evaluationType: 'checkbox'  },
            { label: 'out_of_tune', variable: 'outOfTune', evaluationType: 'VAS' },
          ],
        },
      ],
    },
    {
      label: 'loudness',
      variable: null,
      children: [
        { label: 'adequate_loudness', variable: 'adequateLoudness' },
        { label: 'too_loud', variable: 'tooLoud', evaluationType: 'VAS' },
        { label: 'too_soft', variable: 'tooSoft', evaluationType: 'VAS' },
      ],
    },
    {
      label: 'voice_quality',
      variable: null,
      children: [
        { label: 'normal_voice_quality', variable: 'normalVoiceQuality' },
        {
          label: 'laryngeal_mechanism',
          variable: null,
          children: [
            { label: 'fry', variable: 'fry' },
            { label: 'falsetto', variable: 'falsetto' },
            { label: 'modal_register', variable: 'modalRegister' },
          ],
        },
        {
          label: 'deviant_voice_quality',
          variable: null,
          children: [
            { label: 'aphonia', variable: 'aphonia' },
            { label: 'asthenia', variable: 'asthenia', evaluationType: 'VAS' },
            { label: 'breathiness', variable: 'breathiness', evaluationType: 'VAS'  },
            { label: 'diplophonia', variable: 'diplophonia', evaluationType: 'VAS'  },
            { label: 'roughness', variable: 'roughness', evaluationType: 'VAS'  },
            { label: 'strain', variable: 'strain', evaluationType: 'VAS'  },
            { label: 'wet_gurgly', variable: 'wet', evaluationType: 'VAS'  },
          ],
        },
      ],
    },
    {
      label: 'resonance',
      variable: null,
      children: [
        {
          label: 'oral_resonance',
          variable: null,
          children: [
            { label: 'lack_of_oral_resonance', variable: 'lackOralResonance', evaluationType: 'VAS' },
            { label: 'front_oral_resonance', variable: 'frontOralResonance', evaluationType: 'VAS' },
            { label: 'back_oral_resonance', variable: 'backOralResonance', evaluationType: 'VAS' },
          ],
        },
        {
          label: 'nasality',
          variable: null,
          children: [
            { label: 'hypernasality', variable: 'hypernasality', evaluationType: 'VAS' },
            { label: 'hyponasality', variable: 'hyponasality', evaluationType: 'VAS' },
          ],
        },
      ],
    },
    {
      label: 'length',
      variable: null,
      children: [
        {
          label: 'stability',
          variable: null,
          children: [
            { label: 'pitch_stability', variable: 'pitchStability', evaluationType: 'VAS' },
          ],
        },
        {
          label: 'instability',
          variable: null,
          children: [
            { label: 'aphonic_break', variable: 'aphonicBreak', evaluationType: 'VAS' },
            { label: 'pitch_break', variable: 'pitchBreak', evaluationType: 'VAS' },
            { label: 'pitch_instability', variable: 'pitchInstability', evaluationType: 'VAS' },
            { label: 'spasm', variable: 'spasm', evaluationType: 'VAS' },
            { label: 'tremor', variable: 'tremor', evaluationType: 'VAS' },
          ],
        },
      ],
    },
  ];

  const renderTree = (nodes, parentKey = '') => {
    return nodes.map((node, index) => {
      const key = `${parentKey}-${index}`;
      const hasChildren = node.children && node.children.length > 0;
      const label = node.label;

      return (
        <div key={key} style={{ margin: '10px 0', paddingLeft: parentKey ? '20px' : '0', borderLeft: parentKey ? '1px solid #ccc' : 'none' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            {!hasChildren && node.variable && (
              <input
                type="checkbox"
                checked={!!selectedItems[node.variable]}
                onChange={() => toggleSelect(key, node.variable, node.evaluationType)}
              />
            )}
            <div
              onClick={() => hasChildren && toggleExpand(key)}
              style={{ cursor: hasChildren ? 'pointer' : 'default', marginLeft: hasChildren ? '10px' : '0', fontWeight: hasChildren ? 'bold' : 'normal' }}
            >
              {hasChildren && (expanded[key] ? '▼' : '▶')} {t(`${label}`)}
            </div>
          </div>
          {selectedItems[node.variable]?.evaluationType === 'VAS' && (
            <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
              <input
                type="range"
                min="0"
                max="100"
                value={vasValues[node.variable] || 0}
                onChange={(e) => handleVasChange(node.variable, e.target.value)}
              />
            </div>
          )}
          {hasChildren && expanded[key] && renderTree(node.children, key)}
        </div>
      );
    });
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.5', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
      {renderTree(treeData)}
    </div>
  );
};

export default TreeMenu;